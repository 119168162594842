@import './global.scss';

img.round {
  border-radius: 50%;
  object-fit: cover;
}
img.cover {
  object-fit: cover;
}
img.contain {
  object-fit: contain;
}
