@import './global.scss';

.page {
  width: 100%;
  flex: 1;
  &__title {
    color: $primary-color;
  }
  &--fixed-height {
    display: flex;
    padding: 10px;
    max-height: calc(100vh - 60px);
    overflow: hidden;
  }
  @media screen and (min-width: $nav-media-screen) {
    &--padded {
      padding: 10px;
    }
  }
  &--info-page {
    .page-left {
      width: 100%;
      background-color: #ffffff10;
      margin-bottom: 20px;
      overflow: hidden;
      &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .image-upload {
          margin-bottom: 10px;
        }
        &__name {
          margin-top: 10px;
          font-size: 1.2em;
        }
        .entity-link {
          width: 100%;
          margin-top: 14px;
          padding: 6px 14px;
          background: #ffffff10;
          border-radius: 20px;
        }
      }
      @media screen and (min-width: $info-page-media-screen) {
        position: sticky;
        top: 70px;
        flex: none;
        width: 360px;
        height: calc(100vh - 80px);
        background-color: #ffffff10;
        margin: 10px;
        overflow-y: auto;
        &__top {
          border-bottom: 1px solid #ffffff10;
        }
      }
    }
    .right {
      position: relative;
      width: 100%;
      margin: 10px;
      margin-left: 0;
      background-color: #101010;
      &__title {
        position: sticky;
        top: 0;
        font-size: 1.2em;
        padding: 20px;
        border-bottom: 1px solid #ffffff20;
        background-color: #101010;
        z-index: 1;
      }
    }
    @media screen and (min-width: $info-page-media-screen) {
      display: flex;
    }
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__icon {
    width: 200px;
    height: 200px;
    color: $red;
  }
  &__icon-text {
    font-size: 12em;
    color: $red;
  }
  &__title {
    font-size: 2em;
    margin-bottom: 30px;
  }
}
