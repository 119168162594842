.loading-page .logo-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  .logo {
    position: relative;
    width: 220px;
    margin-bottom: 10px;
  }
  .logo-title {
    position: relative;
    font-size: 2em;
    text-transform: uppercase;
  }
}
