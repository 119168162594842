@import './../../styles/global.scss';

.side-nav {
  position: fixed;
  right: -340px;
  height: 100%;
  width: 100%;
  max-width: 340px;
  transition-duration: 0.3s;
  z-index: 10;
  &__inner-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #1c1e21;
    transition-duration: 0.3s;
    overflow: hidden;
  }
  &--open {
    right: 0;
    box-shadow: 0 0 100px 1px #000;
  }
  @media screen and (min-width: $nav-media-screen) {
    position: sticky;
    top: 60px;
    right: auto;
    width: 60px;
    height: calc(100vh - 60px);
    &__inner-wrapper {
      position: absolute;
      width: 60px;
      left: 0;
      top: 0px;
      bottom: 0;
      background-color: #1c1e21;
      transition-duration: 0.3s;
      overflow: hidden;
      z-index: 10;
      &:hover {
        width: 100%;
        width: 340px;
      }
    }
  }
  &__inner {
    width: 340px;
  }
  .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    color: #bbb;
    height: 60px;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    &__icon-wrapper {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      color: #777;
      font-size: 1.6em;
    }

    &--active {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-color: $secondary-color;
      }
      color: #ddd;
      .nav-link__icon {
        color: #bbb;
      }
    }
    &--logout {
      background-color: #90000030;
    }
  }
}
