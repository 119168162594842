@import './../../styles/global.scss';

.top-nav {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #141517;
  overflow: hidden;
  z-index: 12;
  &__left {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    .logo {
      width: 40px;
      margin: 0 10px;
      margin-bottom: 5px;
    }
    .page-title {
      text-transform: uppercase;
      white-space: pre;
      @media screen and (min-width: $webWidth) {
        margin-left: 20px;
      }
    }
  }
  .nav-toggle {
    height: 60px;
    width: 60px;
    margin-right: -10px;
    background-color: transparent;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    &__icon {
      width: 30px;
      height: 30px;
    }
    @media screen and (min-width: $webWidth) {
      display: none;
    }
  }
  &__icons {
    display: flex;
    align-items: center;
    margin-right: 10px;
    .top-nav__icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
      color: #999;
      background-color: #ffffff10;
      cursor: pointer;
    }
    .top-nav__icon {
      width: 20px;
      height: 20px;
    }
  }
}
