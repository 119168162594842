@import './global.scss';

.form {
  &__title {
    font-size: 1.2em;
    margin: 14px 0;
  }
  &__description {
    margin-bottom: 20px;
    line-height: 1.2em;
    color: #999;
  }
  .select {
    margin-bottom: 15px;
  }
}
.input-wrapper {
  width: 100%;
  margin-bottom: 15px;
  color: #fff;
  &__label {
    display: block;
    color: #bbb;
    margin-bottom: 6px;
  }
  &__error {
    margin-top: 6px;
    color: #b44;
    font-size: 0.9em;
  }
  &--error {
    .input-wrapper__input {
      border-color: #b44;
    }
  }
}

.input-wrapper__input {
  width: 100%;
  padding: 10px;
  color: #ddd;
  border: none;
  outline: none;
  font-size: 16px;
  background: transparent;
  border: 1px solid #444;
  border-radius: 2px;
  &:focus {
    border-color: rgb(4, 104, 115);
  }
  option {
    display: block;
    color: #fff;
    padding: 5px;
    background-color: #444;
  }
}
.react-date-picker {
  width: 100%;
  &__wrapper {
    height: 40px;
    padding: 0 10px;
    border: 1px solid #444 !important;
    flex: 1;
  }
  &__button svg {
    stroke: #999;
  }
  input {
    outline: none;
    background-color: none;
    border: none;
    color: #fff;
  }
}

.form-row {
  display: flex;
  .input-wrapper {
    flex: 1;
    margin-right: 5px;
    &:nth-child(2) {
      margin-right: 0px;
      margin-left: 5px;
    }
  }
}
