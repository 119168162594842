@import './global.scss';

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.trunc-text {
  display: block;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
}
.text-status-active {
  color: #34a734;
}
.text-status-deactivated,
.text-red {
  color: $red;
}
.text-primary-color {
  color: $primary-color;
}
