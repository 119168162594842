$primary-color: #007e8c;
$secondary-color: rgb(4, 104, 115);

$background-color: #000000;
$secondary-background: #ffffff10;

$movement: #4a7a95;
$capacity: #ec9b3d;
$load: #df6253;
$control: #dec72b;
$explode: #24a9dc;

$bronze: #cd7f32;
$silver: silver;
$gold: #ffbe01;

$red: #a73434;

$nav-media-screen: 768px;
$info-page-media-screen: 900px;

$webWidth: 768px;
