@import './global.scss';

.info-table {
  font-size: 0.9em;
  &__main-title {
    padding: 20px;
    font-size: 1.3em;
    border-bottom: 1px solid #ffffff10;
  }
  &__row {
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ffffff10;
    @media screen and (min-width: $info-page-media-screen) {
      display: flex;
    }
  }
  &__title {
    width: 200px;
    padding-top: 5px;
    color: #aaa;
  }
  &__value {
    flex: 1;
    padding-top: 5px;
  }
}
