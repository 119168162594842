@import './global.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 0;
  color: #ddd;
  background: $background-color;
  #root {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    > .root-inner {
      position: relative;
      flex: 1;
      display: flex;
    }
  }
}

* {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff20;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-color;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.loading-icon {
  width: 20px;
  height: 20px;
  animation-name: fullRotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
