@import './global.scss';

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #aaa;
  outline: none;
  padding: 13px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  opacity: 0.9;
  box-sizing: border-box;
  transition: background-color 0.1s;
  overflow: hidden;
  &__text {
    text-transform: uppercase;
  }
  &:hover {
    opacity: 1;
    .button__text {
      color: #fff !important;
    }
  }
  &--main {
    color: #fff;
    background-color: $secondary-color;
  }
  &--secondary {
    color: #fff;
    background: none;
    border: 2px solid $secondary-color;
    &:hover {
      background: $secondary-color;
    }
  }
  &--red {
    color: #fff;
    background: $red;
    border: none;
  }
  &__left-icon {
    position: absolute;
    left: 10px;
  }
  &__text {
    flex: 1;
  }
  .loading-icon-wrapper {
    background-color: inherit;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@keyframes fullRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
